body {
    margin: 0px;
    padding: 0px;
    font-family: 'BT Font', sans-serif;
    color: #333;
}

h1, h2, h3 {
    font-weight: normal;
}

input {
    font-family: 'BT Font', sans-serif;
    font-size: 1.1em;
    padding: 0.5em;
    border: 1px solid #dcdcdc;
    width: calc(100% - 1em - 2px);
}

input:hover {
    border-color: #ababab;
}

input:focus {
    border-color: #6400aa;
}

label {
    display: block;
    font-size: 1em;
    margin-bottom: 0.3em;
    color: #808080;
}

button {
    background-color: #6400aa;
    color: #fff;
    font-family: 'BT Font', sans-serif;
    border: 1px solid #6400aa;
    font-size: 1em;
    padding: 0.6em 1em;
    cursor: pointer;
    transition: 0.2s all;
    border-radius: 0.1em;
}

button:hover {
    background-color: rgb(77, 0, 133);
    border-color: rgb(77, 0, 133);
}

button:disabled {
    background-color: #cdcdcd;
    border-color: #cdcdcd;
    opacity: 0.7;
}

div.buttons {
    margin: 1em -1em -1em;
    background-color: #f0f0f0;
    border-top: 1px solid #dfdfdf;
    padding: 1em;
    text-align: right;
}

div.ResultsCard {
    text-align: center;
}

div.App {
    margin: 0px auto;
    max-width: 80%;
}

div.TestCard {
    max-width: 50%;
    margin: 10vh auto;
    border: 1px solid #dfdfdf;
    padding: 1em;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(51,51,51,0.4);
    -moz-box-shadow: 0px 2px 3px 0px rgba(51,51,51,0.4);
    box-shadow: 0px 2px 3px 0px rgba(51,51,51,0.4);
}

div.TestCard > header > h1 {
    margin-top: 0px;
}

div.ResultMetric {
    width: 5em;
    display: inline-block;
    margin: 0.5em;
    padding: 1em 2em;
    color: #fff;
    text-align: center;
}

    div.ResultMetric span.ResultMetric-speed {
        font-size: 4em;
        display: block;
        font-weight: normal;
    }

    div.ResultMetric span {
        font-size: 1.5em;
        font-weight: lighter;
    }